<template>
  <div class="faq">
    <div class="question-container" @click="toggleOpen(index)">
      <div class="first-column">
        <img src="../assets/images/question.svg" alt="" class="image" />
        <div class="question">{{ faq.question }}</div>
      </div>
      <button class="dropdown">
        <img
          :class="{ rotate: faq.open }"
          class="image-dropdown"
          src="../assets/images/dropdown.svg"
          alt=""
        />
      </button>
    </div>
    <div v-if="faq.open" class="answer">{{ faq.answer }}</div>
  </div>
</template>
<script>
export default {
  name: "FAQ",
  props: ["faq", "index", "faqs"],
  methods: {
    toggleOpen(indexFaq) {
      if (this.faqs[indexFaq].open) {
        this.faqs[indexFaq].open = false;
      } else {
        this.faqs.map((i, index) => {
          this.faqs[index].open = false;
        });
        this.faqs[indexFaq].open = !this.faqs[indexFaq].open;
      }
    }
  }
};
</script>
<style scoped>
.faq {
  padding: 10px 20px;
  /*height: 70px;*/
}

.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.question {
  font-size: 14px;
  font-family: "Vazir Medium FD";
}

.first-column {
  display: flex;
  align-items: center;
  width: 23%;
  width: 100%;
}

.answer {
  margin-top: 20px;
  background: rgb(31, 60, 136, 0.1);
  /* padding: 30px; */
  padding: 10px;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  color: #121212;
  opacity: 0.8;
  font-family: "Vazir Medium FD";
}

.image-dropdown {
  width: 14px;
  transition: 0.25s ease-in-out;
}

.dropdown {
  background: transparent;
  border: none;
  cursor: pointer;
}

.image {
  display: none;
}

.answer {
  font-size: 14px;
}

.rotate {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .image {
    display: block;
    width: 20px;
  }

  .question {
    margin-right: 20px;
  }
}

@media (min-width: 960px) {
  .question {
    font-size: 16px;
  }

  .image {
    width: 30px;
  }

  .image-dropdown {
    width: 20px;
  }

  .answer {
    padding: 30px;
    font-size: 16px;
  }
}

@media (min-width: 1140px) {
  .image {
    width: 45px;
  }

  .image-dropdown {
    width: 20px;
  }

  .answer {
    font-size: 16px;
  }
}
</style>
