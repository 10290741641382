<template>
  <div id="faq">
    <loading :can-cancel="true"></loading>
    <div class="faq-container">
      <div class="header">سوالات متداول</div>
      <div class="collaps-container">
        <FAQ
          :faqs="faqs"
          v-for="(faq, i) in faqs"
          :key="i"
          :faq="faq"
          :index="i"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: {
    FAQ,
    Footer,
    Loading
  },
  data() {
    return {
      faqs: []
    };
  },
  mounted() {
    this.getFaqs();
  },
  methods: {
    toggleOpen(index) {
      console.log(index);
    },
    async getFaqs() {
      let loader = this.$loading.show();
      try {
        let response = await axios("/faq");
        this.faqs = response.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    }
  }
};
</script>
<style scoped>
.faq-container {
  padding: 0 10px;
  margin-top: 70px;
  height: 100vh;
}

.header {
  font-family: "Vazir Medium FD";
  margin-top: 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #070d59;
  margin-bottom: 40px;
}

.image {
  display: none;
}

.faq {
  display: block;
  width: 100%;
  /* max-width: 1300px; */
  margin: 10px auto;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.faq .question {
}

@media (min-width: 960px) {
  .faq-container {
    padding: 0 70px;
    margin-top: auto;
  }
}
</style>
